import iconOne from "@data/images/icons/linea-basic-heart.svg";
import iconTwo from "@data/images/icons/linea-basic-case.svg";
import iconThree from "@data/images/icons/linea-basic-alarm.svg";
import iconFour from "@data/images/icons/linea-basic-geolocalize-05.svg";
import iconFive from "@data/images/icons/linea-ecommerce-money.svg";
import iconSix from "@data/images/icons/linea-basic-spread-text-bookmark.svg";
import iconSeven from "@data/images/icons/linea-music-headphones.svg";
import iconEight from "@data/images/icons/linea-basic-gear.svg";

export const data = [
    {
        id: "box-icon-1-1",
        title: "IT Design",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconOne,
    },
    {
        id: "box-icon-1-2",
        title: "IT Management",
        description:
            "It’s possible to simultaneously manage and transform information from one server to another.",
        path: "/",
        icon: iconTwo,
    },
    {
        id: "box-icon-1-3",
        title: "Data Security",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconThree,
    },
    {
        id: "box-icon-1-4",
        title: "Business Reform",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconFour,
    },
    {
        id: "box-icon-1-5",
        title: "Infrastructure Plan",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconFive,
    },
    {
        id: "box-icon-1-6",
        title: "Firewall Advance",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconSix,
    },
    {
        id: "box-icon-1-7",
        title: "Desktop Computing",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconSeven,
    },
    {
        id: "box-icon-1-8",
        title: "Software Engineering",
        description:
            "We provide the most responsive and functional IT design for companies and businesses worldwide.",
        path: "/",
        icon: iconEight,
    },
];
